import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { pick } from 'rambda';

import { ISitPageState, PAGE__SIT_STATE_KEY } from './sit.state';
import {
  select_displayingScenarios,
  select_highlightedScenario
} from '@/store/scenario/scenario.selectors';
import {
  select_selectedPlan,
  select_selectedDateRange
} from '../layout/layout.selectors';
import { select_selectedSegment } from '../layout/layout.baseSelectors';
import { seriesPairFormatterFactory_v2 } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.utils';
import { IGetDRPDataParams, ISitDataResponse, ISitTreeNode } from '@/app/@core/entity/drp.service';
import {
  calculateDataArea,
  formatByDateAggregationGen,
  takeRowDataById,
  takeTreeNodeChildrenById,
  computeTotalLookupByNames
} from '@/store/pages/sit/sit.utils';
import { select_latestActual } from '@/store/actual/actual.selectors';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import {
  propsToColumns,
  generateShortedScenarioLegend
} from '@/store/pages/demand-planning/demand-planning.utils';
import { PlanFlag } from '@/app/@core/interfaces/business/plan';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { getSITMetricsNamingValues } from '@/app/pages/explorer/sit/sit.utils';

const selectFeature = createFeatureSelector<ISitPageState>(
  PAGE__SIT_STATE_KEY,
);

export const select_drpDateAggregation = createSelector(
  selectFeature,
  (state) => state.dateAggregation,
);
export const select_sitGroupings = createSelector(selectFeature, (state) => state.groupings);
export const select_rawDRP = createSelector(selectFeature, (state) => state.sit);

export const select_formatByDateAggregation = createSelector(
  select_drpDateAggregation,
  select_selectedWorkspace,
  (dateAggregation, workspace) => formatByDateAggregationGen(dateAggregation, workspace?.settings?.fiscalYearStartMonth),
);

export const select_sit_loading = createSelector(
  selectFeature,
  (state) => state.sitLoading
);

export const select_sit_validating = createSelector(
  selectFeature,
  (state) => state.sitValidating
);

export const select_sit_triggeringSimulation = createSelector(
  selectFeature,
  (state) => state.sitTrigerringSimulation
);

export const select_sitValidationError = createSelector(
  selectFeature,
  (state) => state.validationSitDataError
);

export const select_sitTriggeringSimulationError = createSelector(
  selectFeature,
  (state) => state.triggerSimulationError
);

export const select_sitEditedScenarios = createSelector(
  selectFeature,
  (state) => state.editedSITScenarios
);

export const select_sitEditingScenario = createSelector(
  selectFeature,
  (state) => state.editingScenario
);

export const select_sitValidatingScenario = createSelector(
  selectFeature,
  (state) => state.validatingScenario
);

export const select_sitSelectedScenarioForSimulation = createSelector(
  selectFeature,
  (state) => state.selectedScenarioForSimulation
);

export const select_sitSimulatingScenario = createSelector(
  selectFeature,
  (state) => state.simulatingScenario
);

export const select_sitUom = createSelector(
  selectFeature,
  (state) => state.uom,
);

export const select_sitConstrained = createSelector(
  selectFeature,
  (state) => state.useConstrained,
);

export const select_expand_items = createSelector(
  selectFeature,
  (state) => state.mapOfExpandedData,
);

export const select_sitLoadedParams = createSelector(
  selectFeature,
  (state) => state.sitLoadedParams,
);

export const select_combinedSitTableData = createSelector(
  selectFeature,
  select_selectedPlan,
  select_displayingScenarios,
  (state, selectedPlan, displayingScenarios): ISitDataResponse => {
    if (!selectedPlan || !select_latestActual) {
      return { columns: [], rows: [], tree: [] };
    }

    // Should not show committed and based emand for now
    const scenarios = displayingScenarios.map(pick(['id', 'name', 'color']))

    const rows: ISitDataResponse['rows'] = scenarios.map((s) => {
      let drp: any = [];

      if (s?.id?.includes("actual-")) {
        drp = takeRowDataById(s.id, state.drpActualX.rows) || [];
      } else {
        drp = takeRowDataById(s.id, state.sit.rows) || [];
      }

      return [s.id, drp];
    });

    const tree: ISitDataResponse['tree'] = scenarios.map((s) => {
      let drp: any = [];

       if (s?.id?.includes("actual-")) {
        drp = takeTreeNodeChildrenById(s.id, state.drpActualX.tree) || [];
      } else {
        drp = takeTreeNodeChildrenById(s.id, state.sit.tree) || [];
      }

      return <ISitTreeNode>{
        key: s.id,
        label: s.name,
        data: takeRowDataById(s.id, rows),
        children: drp
      };
    });

    return { columns: state.sit.columns, rows, tree };
  },
);

export const select_sitChartOptions = createSelector(
  select_combinedSitTableData,
  select_selectedWorkspace,
  select_rawDRP,
  select_displayingScenarios,
  select_highlightedScenario,
  select_drpDateAggregation,
  (
    combinedSitTableData,
    workspace,
    rawDRP,
    displayingScenarios,
    highlightedScenario,
    dateAggregation,
  ): EChartsOption => {
    const colFormatter = formatByDateAggregationGen(dateAggregation, workspace?.settings?.fiscalYearStartMonth);
    const scenarios = displayingScenarios.map(pick(['id', 'name', 'color']));

    const totalMap = computeTotalLookupByNames(combinedSitTableData, scenarios);
    const acceptedScale = workspace ? workspace.settings?.acceptedScale : []

    let barWidth = 17

    const numberOfCols = combinedSitTableData.columns.length

    if (numberOfCols > 15 && numberOfCols <= 20) {
      barWidth = 12
    } else if (numberOfCols > 20 && numberOfCols <= 30) {
      barWidth = 7
    } else if (numberOfCols > 30) {
      barWidth = 5
    }

    return <EChartsOption>{
      tooltip: {
        formatter: seriesPairFormatterFactory_v2(highlightedScenario?.id, workspace, false, true, scenarios, acceptedScale, colFormatter),
        trigger: 'axis',
        position: function (point) {
          // 'point' parameter is the mouse position
          var x = point[0];  // x coordinate
          var y = point[1] + 10;  // y coordinate + offset
          return [x, y];
        }
      },
      title: {
        text: 'SIT chart',
        textStyle: { fontWeight: 500, fontFamily: 'Roboto', fontSize: 14, color: '#00355C' },
      },
      grid: { bottom: 20, containLabel: true, left: 200, right: 0 },
      legend: {
        type:'scroll',
        // Do total here
        formatter: (id) => {
          const fixedDRPId = [' SIT', ' CLS', ' S.In', ' S.Out']
          const matchingElements = fixedDRPId.filter((s) => id.includes(s));
          const scenarioId = id.split(matchingElements[0])[0]
          const matchScenario = scenarios.find(s => s?.id == scenarioId)

          const transformMatchingElements = matchingElements.map(i => getSITMetricsNamingValues(i.trim(), workspace))

          if (matchScenario) {
            // const avgStats = totalMap[matchScenario.name].get(matchingElements[0]) / combinedSitTableData.columns.length
            return `${generateShortedScenarioLegend(matchScenario.name)}\n ${transformMatchingElements[0]}: ${transformInputToKpiFormat(
              totalMap[matchScenario.name].get(matchingElements[0]),'-', [], 3, '')}`;
          }

          return id
        },
        textStyle: {
          fontSize: 12,
          color: '#686868',
          fontFamily: 'simcel-Bw-Mitga'
        },
        padding: [7, 30, 7, 20],
        borderColor: '#fff',
        itemHeight: 10,
        itemGap: 15,
        inactiveBorderColor: '#fff',
        left: 'left',
        orient: 'vertical',
        top: 50
      },
      xAxis: [{
        type: 'category',
        onZero: true,
        axisLine: {
          lineStyle: {
            type: 'solid',
            color: '#fff',
          }
        },
        axisLabel: {
          fontFamily: 'simcel-Bw-Mitga',
          color: '#484848',
          formatter: formatByDateAggregationGen(DateAggregationOption.MONTH, workspace?.settings?.fiscalYearStartMonth)
        },
        axisTick: {
          alignWithLabel: true
        },
        data: combinedSitTableData.columns,
      }],
      yAxis: [
        {
          type: 'value',
          name: 'Days'
        },
        {
          type: 'value',
          position: 'right',
          axisLabel: {
            formatter: (v: string | number) => transformInputToKpiFormat(v),
            fontFamily: 'simcel-Bw-Mitga',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            lineHeight: 14,
            color: '#484848',
            margin: 40,
          }
        }
      ],
      color: scenarios.map((s) => s.color || '#0C80EB'),
      series: scenarios.flatMap(({ id, color }) => [
        <LineSeriesOption>{
          id: id + '_cls',
          name: id + ' CLS',
          type: 'line',
          yAxisIndex: 1,
          data: takeRowDataById(id, combinedSitTableData.rows)?.map(i => i?.closingStocks),
          itemStyle: { 
            borderWidth: 1,
            borderColor: color,
            color
          },
          lineStyle: {
            type: 'dashed'
          },
          symbol: 'circle',
          symbolSize: 8,
          showAllSymbol: true,
        },
        {
          id: id + '_s.in',
          name: id + ' S.In',
          type: 'line',
          yAxisIndex: 1,
          data: takeRowDataById(id, combinedSitTableData.rows)?.map(i => i?.salesIn),
          itemStyle: { 
            borderWidth: 1,
            borderColor: color,
            color
          },
          lineStyle: {
            type: 'dotted'
          },
          symbol: 'circle',
          symbolSize: 8,
          showAllSymbol: true,
        },
        {
          id: id + '_s.out',
          name: id + ' S.Out',
          type: 'line',
          yAxisIndex: 1,
          data: takeRowDataById(id, combinedSitTableData.rows)?.map(i => i?.distributorSalesOut),
          itemStyle: { 
            borderWidth: 1,
            borderColor: color,
            color
          },
          lineStyle: {
            type: 'solid'
          },
          symbol: 'circle',
          symbolSize: 8,
          showAllSymbol: true,
        },
        {
          id: id + '_sit',
          name: id + ' SIT',
          type: 'bar',
          yAxisIndex: 0,
          data: takeRowDataById(id, combinedSitTableData.rows)?.map(i => i?.stocksInTrade),
          label: {
            rotate: 30
          },
          legendHoverLink: false,
          selectedMode: 'series',
          barWidth,
          itemStyle: {
            barBorderRadius: barWidth / 2,
            borderWidth: 1,
            borderType: 'solid',
            borderColor: '#979797',
            color,
            opacity: 0.4
          },
          showAllSymbol: true,
        }
      ])
    };
  },
);

/** Compose the param to load Demand Chart data */
export const select_params_loadDRPData = createSelector(
  select_selectedPlan,
  select_selectedSegment,
  select_selectedDateRange,
  select_sitGroupings,
  select_drpDateAggregation,
  select_sitUom,
  select_sitConstrained,
  (
    selectedPlan,
    selectedSegment,
    selectedDateRange,
    drpGroupings,
    dateAggregation,
    uom,
    constrained
  ): IGetDRPDataParams | undefined => {
    if (!selectedPlan) return;

    return <IGetDRPDataParams>{
      planId: selectedPlan.id,
      isActual: selectedPlan.flags?.includes(PlanFlag.ACTUAL),
      interval: dateAggregation,
      segment: selectedSegment,
      dateRange: selectedDateRange?.start && {
        start: selectedDateRange?.start,
        end: selectedDateRange?.end
      },
      groupingColumns: propsToColumns(drpGroupings),
      forecastCollection: selectedPlan.forecastCollection,
      uom,
      useConstrained: constrained
    };
  }
);
